<template>
  <div class="Solutions">
    <div class="g-container container">
      <h2 class="g-title title">Solution</h2>
      <div class="cardContainer">
        <div class="cardRow">
          <div
            :class="['cardList', { 'advantageShow-1': true }]"
            id="advantage-show-1"
          >
            <div class="cardBottom">
              <div class="cardRightTop">Digital Warehouse</div>
              <div class="cardRightBottom">
                Digital warehouse and warehouse financing platform
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="['cardList', { 'advantageShow-2': advantageShow }]"
            id="advantage-show-1"
          >
            <div class="cardBottom">
              <div class="cardRightTop">E-commerce Platform</div>
              <div class="cardRightBottom">
                Blockchain based B2B e-commerce platform for supply chain
                scenarios to increase the authenticity and verifiability of data
                and their usage
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="[
              'cardList',
              'cardList3',
              { 'advantageShow-3': advantageShow },
            ]"
            id="advantage-show-1"
          >
            <div class="cardBottom">
              <div class="cardRightTop">Logistics</div>
              <div class="cardRightBottom">
                Unique identifiers for hazardous chemicals base on the
                blockchain technology and IOT devices to ensure data
                transparency in the entire cycle
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="[
              'cardList',
              'cardList4',
              { 'advantageShow-4': advantageShow },
            ]"
            id="advantage-show-1"
          >
            <div class="cardBottom">
              <div class="cardRightTop">Automobile</div>
              <div class="cardRightBottom">
                Privacy protection and trustworthy data collaboration solution
                for Daimler, with joint efforts from Daimler Blockchain Factory
                and Onchain.
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="[
              'cardList',
              'cardList5',
              { 'advantageShow-5': advantageShow },
            ]"
            id="advantage-show-1"
          >
            <div class="cardBottom">
              <div class="cardRightTop">Healthcare</div>
              <div class="cardRightBottom">
                Digital infrastructure for healthcare sector incorporating data
                from 27 hospitals.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solutions',
  data() {
    return {
      advantageShow: false,
      idIndex: [1],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      // 视窗高度
      const domHeight = window.screen.height;
      // dom滚动位置
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop;
      // 获取监听元素
      let id;
      // 获取元素本身高度
      let scrollHeight;
      // 获取监听元素距离视窗顶部距离
      let offsetTop;
      // 获取监听元素距离顶部高度-窗口高度
      let top;
      // 元素距离底部的高度+元素本身高度
      let bottom;
      // eslint-disable-next-line array-callback-return
      this.idIndex.map((i) => {
        id = document.getElementById(`advantage-show-${i}`);
        // eslint-disable-next-line prefer-destructuring
        scrollHeight = id.scrollHeight;
        // eslint-disable-next-line prefer-destructuring
        offsetTop = id.offsetTop;
        top = offsetTop - domHeight > 0 ? offsetTop - domHeight : 0;
        bottom = scrollHeight + offsetTop;
        // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
        if (scrollTop >= top && scrollTop <= bottom) {
          this.advantageShow = true;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.Solutions {
  padding: 80px 0 0;
  margin: auto;
  background-color: #ffffff;
  @media (max-width: 960px) {
    padding: 0;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    text-align: center;
    line-height: 80px;
    margin-bottom: 76px;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    .cardRow {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 80px;
      @media (max-width: 960px) {
        flex-direction: column;
        .space {
          height: 20px !important;
        }
      }
      .space {
        height: 360px;
        width: 40px;
      }
      .cardList {
        display: flex;
        flex: 1;
        border-radius: 16px;
        height: 360px;
        flex-direction: column;
        padding: 40px 15px;
        // width: 210px;
        margin: auto;
        .cardTop {
          width: 90px;
        }
        .cardRightTop {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          width: 210px;
          color: #ffffff;
        }
        .cardRightBottom {
          font-style: normal;
          font-weight: normal;
          width: 180px;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.01em;
          margin-top: 10px;
          color: #ffffff;
          opacity: 0.5;
          @media (max-width: 960px) {
            width: 100%;
          }
        }
      }
    }
    .advantageShow-1 {
      animation-fill-mode: both;
      animation-duration: 1s;
      animation-name: scrollFadeInUp1;
    }

    @keyframes scrollFadeInUp1 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
        background: #0045ff;
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
        background: url("~@/assets/solution1.png") no-repeat;
        background-size: 100%;
      }
    }

    .advantageShow-2 {
      animation-fill-mode: both;
      animation-duration: 2s;
      animation-name: scrollFadeInUp2;
    }

    @keyframes scrollFadeInUp2 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
        background: #0045ff;
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
        background: url("~@/assets/solution2.png") no-repeat;
        background-size: 100%;
      }
    }

    .advantageShow-3 {
      animation-fill-mode: both;
      animation-duration: 3s;
      animation-name: scrollFadeInUp3;
    }

    @keyframes scrollFadeInUp3 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
        background: #0045ff;
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
        background: url("~@/assets/solution3.png") no-repeat;
        background-size: 100%;
      }
    }

    .advantageShow-4 {
      animation-fill-mode: both;
      animation-duration: 4s;
      animation-name: scrollFadeInUp4;
    }

    @keyframes scrollFadeInUp4 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
        background: #0045ff;
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
        background: url("~@/assets/solution4.png") no-repeat;
        background-size: 100%;
      }
    }

    .advantageShow-5 {
      animation-fill-mode: both;
      animation-duration: 5s;
      animation-name: scrollFadeInUp5;
    }

    @keyframes scrollFadeInUp5 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
        background: #0045ff;
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
        background: url("~@/assets/solution5.png") no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
