<template>
  <div class="Advantages">
    <div class="g-container container">
      <h2 class="g-title title">Advantages</h2>
      <div class="cardContainer">
        <div class="cardRow">
          <div
            :class="['cardList', { 'advantageShow-1': advantageShow }]"
            id="advantage-show-1"
          >
            <div class="cardTop">
              <img src="@/assets/homeImg8.svg" alt="" />
            </div>
            <div class="cardBottom">
              <div class="cardRightTop">Multiple scenarios</div>
              <div class="cardRightBottom">
                Users can choose from 30+ payment methods, including bank cards,
                mainstream mobile payment providers, e-wallets, etc. Merchants
                can collect payments anytime and anywhere via PC, mobile apps,
                and HTML 5 pages.
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="['cardList', { 'advantageShow-2': advantageShow }]"
            id="advantage-show-1"
          >
            <div class="cardTop">
              <img src="@/assets/homeImg9.svg" alt="" />
            </div>
            <div class="cardBottom">
              <div class="cardRightTop">Multiple currencies</div>
              <div class="cardRightBottom">
                Inst supports more than 20 global mainstream settlement
                currencies such as USD, EUR, JPY and all mainstream
                cryptocurrencies including BTC, ETH and USDT.
              </div>
            </div>
          </div>
          <div class="space"></div>
          <div
            :class="['cardList', { 'advantageShow-3': advantageShow }]"
            id="advantage-show-1"
          >
            <div class="cardTop">
              <img src="@/assets/homeImg10.svg" alt="" />
            </div>
            <div class="cardBottom">
              <div class="cardRightTop">Multiple ecosystems</div>
              <div class="cardRightBottom">
                Inst links cross-industry and cross-border transaction services,
                which helps generate more innovation and development of the new
                frontier.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Advantages',
  data() {
    return {
      advantageShow: false,
      idIndex: [1],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      // 视窗高度
      const domHeight = window.screen.height;
      // dom滚动位置
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop;
      // 获取监听元素
      let id;
      // 获取元素本身高度
      let scrollHeight;
      // 获取监听元素距离视窗顶部距离
      let offsetTop;
      // 获取监听元素距离顶部高度-窗口高度
      let top;
      // 元素距离底部的高度+元素本身高度
      let bottom;
      // eslint-disable-next-line array-callback-return
      this.idIndex.map((i) => {
        id = document.getElementById(`advantage-show-${i}`);
        // eslint-disable-next-line prefer-destructuring
        scrollHeight = id.scrollHeight;
        // eslint-disable-next-line prefer-destructuring
        offsetTop = id.offsetTop;
        top = offsetTop - domHeight > 0 ? offsetTop - domHeight : 0;
        bottom = scrollHeight + offsetTop;
        // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
        if (scrollTop >= top && scrollTop <= bottom) {
          this.advantageShow = true;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.Advantages {
  padding: 160px 0 160px;
  margin: auto;
  background-color: #ffffff;
  @media (max-width: 960px) {
    padding: 80px 0 0;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    text-align: center;
    line-height: 80px;
    margin-bottom: 76px;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    .cardRow {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 80px;
      @media (max-width: 960px) {
        flex-direction: column;
        .space {
          height: 20px !important;
        }
      }
      .space {
        height: 440px;
        width: 40px;
      }
      .cardList {
        display: flex;
        flex: 1;
        background: #ffffff;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        height: 440px;
        flex-direction: column;
        padding: 40px 30px;
        .cardTop {
          width: 90px;
        }
        .cardRightTop {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.01em;
          margin-top: 30px;
          width: 310px;
        }
        .cardRightBottom {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.01em;
          opacity: 0.3;
          margin-top: 30px;
        }
      }
    }
    .advantageShow-1 {
      animation-fill-mode: both;
      animation-duration: 1s;
      animation-name: scrollFadeInUp1;
    }

    @keyframes scrollFadeInUp1 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
      }
    }

    .advantageShow-2 {
      animation-fill-mode: both;
      animation-duration: 2s;
      animation-name: scrollFadeInUp2;
    }

    @keyframes scrollFadeInUp2 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
      }
    }

    .advantageShow-3 {
      animation-fill-mode: both;
      animation-duration: 3s;
      animation-name: scrollFadeInUp3;
    }

    @keyframes scrollFadeInUp3 {
      0% {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
      }
    }
  }
}
</style>
