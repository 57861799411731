<template>
  <div class="ResearchTeam">
    <div class="g-container container">
      <h2 class="g-title title">Research and Team</h2>
      <div class="cardContainer">
        <div class="cardListContainer">
          <div class="cardList">
            <img src="@/assets/homeImg15.svg" alt="" />
            <div class="cardTitle">50+ Patents and Software Copyrights</div>
            <div class="cardTips">
              Patents about consensus nodes/algorithms, block generation,
              information transmission, cross-chain, etc.
            </div>
          </div>
          <div class="cardList">
            <img src="@/assets/homeImg16.svg" alt="" />
            <div class="cardTitle">Acknowledgement from Developers</div>
            <div class="cardTips">Over 5000 stars on Github</div>
          </div>
        </div>
        <div class="cardListContainer">
          <div class="cardList">
            <img src="@/assets/homeImg17.svg" alt="" />
            <div class="cardTitle">Professional Team</div>
            <div class="cardTips">
              Core members graduated prestigious universities such as Ivy League
              schools. They worked for commercial banks, UnionPay, Fortune 500
              and first-tier Internet companies at home and abroad
            </div>
          </div>
          <div class="cardList">
            <img src="@/assets/homeImg18.svg" alt="" />
            <div class="cardTitle">Institution Cooperation</div>
            <div class="cardTips">
              Academic level cooperation with Fudan, Jiaotong University and
              other top universities in China.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearchTeam',
};
</script>

<style lang="scss" scoped>
.ResearchTeam {
  padding: 160px 0 80px;
  margin: auto;
  background-color: #ffffff;
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    text-align: center;
    line-height: 80px;
    margin-bottom: 76px;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    .cardListContainer {
      display: flex;
      flex-direction: row;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .cardList {
        flex: 1;
        text-align: center;
        height: 350px;
        padding: 60px;
        img {
          margin-bottom: 30px;
        }
        .cardTitle {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          letter-spacing: -0.01em;
        }
        .cardTips {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.01em;
          opacity: 0.3;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
