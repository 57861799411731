var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Advantages"},[_c('div',{staticClass:"g-container container"},[_c('h2',{staticClass:"g-title title"},[_vm._v("Advantages")]),_c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardRow"},[_c('div',{class:['cardList', { 'advantageShow-1': _vm.advantageShow }],attrs:{"id":"advantage-show-1"}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"space"}),_c('div',{class:['cardList', { 'advantageShow-2': _vm.advantageShow }],attrs:{"id":"advantage-show-1"}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"space"}),_c('div',{class:['cardList', { 'advantageShow-3': _vm.advantageShow }],attrs:{"id":"advantage-show-1"}},[_vm._m(4),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardTop"},[_c('img',{attrs:{"src":require("@/assets/homeImg8.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Multiple scenarios")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Users can choose from 30+ payment methods, including bank cards, mainstream mobile payment providers, e-wallets, etc. Merchants can collect payments anytime and anywhere via PC, mobile apps, and HTML 5 pages. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardTop"},[_c('img',{attrs:{"src":require("@/assets/homeImg9.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Multiple currencies")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Inst supports more than 20 global mainstream settlement currencies such as USD, EUR, JPY and all mainstream cryptocurrencies including BTC, ETH and USDT. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardTop"},[_c('img',{attrs:{"src":require("@/assets/homeImg10.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Multiple ecosystems")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Inst links cross-industry and cross-border transaction services, which helps generate more innovation and development of the new frontier. ")])])
}]

export { render, staticRenderFns }