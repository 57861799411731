<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <BusinessModel />
      <Solutions />
      <Advantages />
      <CaseStudy />
      <ResearchTeam />
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
// import LoginModal from '@/components/LoginModal.vue';
import BusinessModel from '@/views/BusinessSolutions/BusinessModel.vue';
import Solutions from '@/views/BusinessSolutions/Solutions.vue';
import Advantages from '@/views/BusinessSolutions/Advantages.vue';
import CaseStudy from '@/views/BusinessSolutions/CaseStudy.vue';
import ResearchTeam from '@/views/BusinessSolutions/ResearchTeam.vue';
import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'BusinessSolutions',
  components: {
    NavHeader,
    NavHeaderMobile,
    // LoginModal,
    BusinessModel,
    Solutions,
    Advantages,
    CaseStudy,
    ResearchTeam,
    NewFooter,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #ffffff;
}
</style>
