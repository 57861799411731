<template>
  <div class="BusinessModel">
    <div class="g-container container">
      <h2 class="g-title title">Business Model</h2>
      <div class="cardContainer">
        <div class="cardRow">
          <div class="cardList">
            <img src="@/assets/BusinessImg2.png" alt="" />
            <h3>Tech & Products</h3>
            <span>Healthcare, government affairs, supply chain finance</span>
          </div>
          <div class="space"></div>
          <div class="cardList">
            <img src="@/assets/BusinessImg4.png" alt="" />
            <h3>Industry &Technology &Finance</h3>
            <span
              >Cross-border payment, Healthcare, Automobile, Commodities,
              Government affairs, Agriculture
            </span>
          </div>
        </div>
        <div class="cardRow">
          <div class="cardList">
            <img src="@/assets/BusinessImg3.png" alt="" />
            <h3>Fintech-as-a-service</h3>
            <span
              >We believe in building the global financial infrastructure to
              scale the digital economy.</span
            >
          </div>
          <div class="space"></div>
          <div class="cardList">
            <img src="@/assets/BusinessImg1.png" alt="" />
            <h3>SaaS Solutions</h3>
            <span
              >Provide comprehensive financial solutions based on payments
              gateway for SaaS for the merchants and industry</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessModel',
};
</script>

<style lang="scss" scoped>
.BusinessModel {
  padding: 160px 0 0;
  margin: auto;
  background-color: #ffffff;
  @media (max-width: 960px) {
    padding: 80px 0 0;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    text-align: center;
    line-height: 80px;
    margin-bottom: 76px;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
    .cardRow {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 80px;
      @media (max-width: 960px) {
        width: 100%;
        flex-direction: column;
      }
      .space {
        width: 80px;
        @media (max-width: 960px) {
          height: 80px;
          width: 100%;
        }
      }
      .cardList {
        display: flex;
        flex: 1;
        flex-direction: column;
        img {
          border-radius: 20px;
        }
        h3 {
          margin-top: 30px;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
        }
        span {
          margin-top: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.3;
        }
        @media (max-width: 960px) {
          img {
            display: block;
            width: 100%;
          }
          h3 {
            padding: 0 10px;
          }
          span {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>
