<template>
  <div class="CaseStudy">
    <div class="g-container container">
      <h2 class="g-title title">Case Studies</h2>
      <div class="cardList">
        <div class="cardListLeft" id="cardListLeft1">
          <img src="@/assets/homeImg11.png" alt="" />
        </div>
        <div class="cardListRight" id="cardListRight1">
          <div class="cardListRightTitle">Swiss digital asset project</div>
          <div class="cardListRightTips">
            <div class="cardListRightTipsText">
              Creating a brand new scenario and demand for new users:
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              NFT account management system
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">Fiat currency escrow system</div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              Cross-border settlement and exchange system
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">On-chain issuance system</div>
          </div>
        </div>
      </div>
      <div class="cardList cardListRevest">
        <div class="cardListRight" id="cardListLeft2">
          <div class="cardListRightTitle">
            Reserve fund escrow account project
          </div>
          <div class="cardListRightTips">
            <div class="cardListRightTipsText">
              Creating a new escrow for 2B2C custodian scenario, fund compliance
              custodian and unrestricted fund circulation, financial innovation:
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              NFT account management system
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              Multi-signature contract system
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              Distributed deployment system
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">On-chain issuance system</div>
          </div>
        </div>
        <div class="cardListLeftDfc" id="cardListRight2">
          <img src="@/assets/homeImg12.png" alt="" />
        </div>
      </div>
      <div class="cardList">
        <div class="cardListLeft" id="cardListLeft3">
          <img src="@/assets/homeImg13.png" alt="" />
        </div>
        <div class="cardListRight" id="cardListRight3">
          <div class="cardListRightTitle">Cross-chain settlement project</div>
          <div class="cardListRightTips">
            <div class="cardListRightTipsText">
              For merchants focusing on niche markets and long-tail businesses:
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              Empowering & innovative settlement channel
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">Swap system</div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">
              Centralized/decentralized exchange platform
            </div>
          </div>
          <div class="cardListRightTips">
            <div class="tipsSpin"></div>
            <div class="cardListRightTipsText">On-chain management system</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'CaseStudy',
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      // 视窗高度
      const domHeight = window.screen.height;
      // dom滚动位置
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop;
      if (window.screen.width > 960) {
        // eslint-disable-next-line array-callback-return
        [1, 2, 3].map((item) => {
          // 获取监听元素
          const cardList1 = document.getElementById(`cardListLeft${item}`);
          const cardListRight1 = document.getElementById(
            `cardListRight${item}`,
          );
          // 获取元素本身高度
          // eslint-disable-next-line prefer-destructuring
          const scrollHeight = cardList1.scrollHeight;
          // 获取监听元素距离视窗顶部距离
          // eslint-disable-next-line prefer-destructuring
          const offsetTop = cardList1.offsetTop;
          // 获取监听元素距离顶部高度-窗口高度
          const top = offsetTop - domHeight > 0 ? offsetTop - domHeight : 0;
          // 元素距离底部的高度+元素本身高度
          const bottom = scrollHeight + offsetTop;
          // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
          if (scrollTop >= top && scrollTop <= bottom) {
            anime({
              targets: cardList1,
              translateX: 60,
              duration: 3000,
            });
            anime({
              targets: cardListRight1,
              translateX: -60,
              duration: 3000,
            });
          }
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.CaseStudy {
  padding: 80px 0 0;
  @media (max-width: 960px) {
    padding: 80px 0 0;
  }
  margin: auto;
  background-color: #ffffff;
  .container {
    padding-left: 0;
    padding-right: 0;
    .title {
      text-align: center;
      line-height: 80px;
      margin-bottom: 76px;
    }
    .cardList {
      display: flex;
      flex-direction: row;
      margin-bottom: 120px;
      @media (max-width: 960px) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
      }
      .cardListLeftDfc {
        width: 480px;
        margin-left: 120px;
        @media (max-width: 960px) {
          margin-left: 0 !important;
          width: 100% !important;
          img {
            width: 100% !important;
          }
        }
        img {
          width: 100%;
        }
      }
      .cardListLeft {
        width: 480px;
        margin-right: 240px;
        img {
          width: 480px;
          height: 270px;
        }
        @media (max-width: 960px) {
          margin-bottom: 20px;
          width: 100% !important;
          img {
            width: 100% !important;
          }
        }
      }
      .cardListLeftDfc {
        width: 480px;
        margin-left: 120px;
        img {
          width: 100%;
        }
      }
      .cardListRight {
        flex: 1;
        @media (max-width: 960px) {
          padding: 20px;
        }
        .cardListRightTitle {
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 40px;
          margin-bottom: 20px;
        }
        .cardListRightTips {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          max-width: 600px;
          .tipsSpin {
            width: 4px;
            height: 4px;
            background: #0045ff;
            margin-right: 16px;
          }
          .cardListRightTipsText {
            flex: 1;
            font-family: Poppins-Regular;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    .cardListRevest {
      @media (max-width: 960px) {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
