var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Solutions"},[_c('div',{staticClass:"g-container container"},[_c('h2',{staticClass:"g-title title"},[_vm._v("Solution")]),_c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardRow"},[_c('div',{class:['cardList', { 'advantageShow-1': true }],attrs:{"id":"advantage-show-1"}},[_vm._m(0)]),_c('div',{staticClass:"space"}),_c('div',{class:['cardList', { 'advantageShow-2': _vm.advantageShow }],attrs:{"id":"advantage-show-1"}},[_vm._m(1)]),_c('div',{staticClass:"space"}),_c('div',{class:[
            'cardList',
            'cardList3',
            { 'advantageShow-3': _vm.advantageShow },
          ],attrs:{"id":"advantage-show-1"}},[_vm._m(2)]),_c('div',{staticClass:"space"}),_c('div',{class:[
            'cardList',
            'cardList4',
            { 'advantageShow-4': _vm.advantageShow },
          ],attrs:{"id":"advantage-show-1"}},[_vm._m(3)]),_c('div',{staticClass:"space"}),_c('div',{class:[
            'cardList',
            'cardList5',
            { 'advantageShow-5': _vm.advantageShow },
          ],attrs:{"id":"advantage-show-1"}},[_vm._m(4)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Digital Warehouse")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Digital warehouse and warehouse financing platform ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("E-commerce Platform")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Blockchain based B2B e-commerce platform for supply chain scenarios to increase the authenticity and verifiability of data and their usage ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Logistics")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Unique identifiers for hazardous chemicals base on the blockchain technology and IOT devices to ensure data transparency in the entire cycle ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Automobile")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Privacy protection and trustworthy data collaboration solution for Daimler, with joint efforts from Daimler Blockchain Factory and Onchain. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardBottom"},[_c('div',{staticClass:"cardRightTop"},[_vm._v("Healthcare")]),_c('div',{staticClass:"cardRightBottom"},[_vm._v(" Digital infrastructure for healthcare sector incorporating data from 27 hospitals. ")])])
}]

export { render, staticRenderFns }